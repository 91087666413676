export default {
    are_you_sure: "Sei sicuro?",
    yes: "Sì",
    no: "No",
    back: "Indietro",
    copied_clipboard: "Copiato",
    filters: "Filtri",
    section: "Sezione",
    reset: "Reset",
    subscribed: "Iscritto",
    following: "Segui",
    discover: "Scopri",
    age: "Età",
    sex: "Sesso",
    male: "Maschio",
    female: "Femmina",
    transgender: "Transgender",
    save: "Salva",
    add: "Aggiungi",
    tags: "Tag",
    done: "Fatto",
    minimum_payment: "Pagamento Minimo",
    receive_payment: "Giorni per Ricevere Pagamento",
    check_out_more:"Scopri di più",
    days: "Giorni",
    hours: "Ora",
    minutes: "Minuto",
    use_default: "Usa come predefinito",

    unlock_verify_account: "Per sbloccare, verifica il tuo account",
    messages_disabled: "Ops.. Messaggi disabilitati",
    not_contents: "Non hai ancora contenuti",
    direct_message_title: "Messaggio Diretto",
    direct_message_description: "Scrivi un messaggio",
    subscribe: "ISCRIVITI",
    month: "Mese",
    direct_message: "MESSAGGIO DIRETTO",
    balance_insufficient:
        "Ops.. il tuo saldo è insufficiente. Puoi ricaricare semplicemente cliccando sul pulsante qui sotto",
    positive_balance_error_on_account_deletion:
        "Devi prelevare i fondi dal portafoglio prima di eliminare il tuo profilo",
    has_subscribers_error_on_account_deletion:
        "Il tuo account verrà eliminato quando il tuo ultimo iscritto scadrà il {renewal_at} assicurati di prelevare tutti i tuoi fondi o il tuo account non verrà eliminato",
    wallet: "portafoglio",
    report: "Segnala",
    report_placeholder: "Dicci perché vuoi segnalare",
    send: "Invia",
    subscriptions: "Iscrizioni",
    subscription_include: "L'iscrizione include:",
    active_until: "Attivo fino a",
    support_model: "Supporta il Noodzler",
    unlock_all_stories: "Sblocca tutti i contenuti",
    unlimited_direct_messages: "Messaggi Diretti Illimitati",
    unlock_content: "Sblocca contenuto per",
    confirm_ending_stream: "Sei sicuro di voler terminare lo streaming?",
    no_streams_scheduled: `{username} non ha nessuno streaming programmato`,
    not_available_to_start_streaming:
        "Il flusso inizierà il {date}, in {difference}",
    streams_restricted: "Sei stato sospeso",
    cancel_subscription: "Annulla iscrizione",
    cancel_subscription_desc:
        "La tua iscrizione terminerà alla prossima data di rinnovo e non verrai addebitato.",
    your_must: "Devi",
    your_must_before_content:"Per mantenere Noodzly un posto più sicuro, devi farlo",
    verify_account: "verificare il tuo account",
    unlock: "Sblocca",
    update: "Aggiorna",
    open: "Apri",
    reset_password: "Reimposta Password",
    password: "Password",
    new_password: "Nuova Password",
    verify_password: "Verifica Password",
    forget_password: "Password Dimenticata",
    password_reset_confirmed: "Reset Password Confermato",
    error_token_email: "Il token e l'email sono richiesti.",
    email: "Email",
    write_something: "Cosa Succede?",
    my_story: "LA MIA STORIA",
    subscribers: "ISCRITTI",
    chat: "CHAT",
    free: "Gratis",
    post: "POST",
    tags_hint: "Aggiungi al massimo 3 tag",
    boost_tags_hint: "Aggiungi al massimo 10 tag",
    redo: "Rifai",
    set_image: "Imposta Immagine",
    purchased: "ACQUISTATO",
    gives_you: "Ha Inviato",
    tips: "SUGGERIMENTI",
    total: "totale",
    delete_story_hint:
        "Non perderai i tuoi guadagni, ma se qualcuno l'ha già acquistato, verrà eliminato dopo 24 ore dall'acquisto.",
    modify_content: "Modifica il tuo contenuto",
    your_message: "Il tuo Messaggio",
    profile_link: "Link Profilo",
    unblock: "Sblocca",
    block: "Blocca",
    subscribe_for: "Iscriviti per",
    top_up: "Ricarica",
    top_up_via_crypto: "Ricarica tramite Crypto",
    top_up_via_card: "Ricarica tramite Carta",
    top_up_warning: "* IVA e tassa di transazione possono applicare",
    active: "Attivo",
    expired: "Scaduto",
    cancel: "Annulla",
    renew: "Rinnova",
    balance: "Saldo",
    withdraw: "Preleva",
    my_subscriptions: "Le Mie Iscrizioni",
    from: "Da",
    to: "A",
    email_verify: "Verifica Email",

    or: "o",
    delete: "elimina",
    unfollow: "smetti di seguire",
    something_is_missing: "Ops.. Qualcosa manca",
    to_turn_off_price: "per disattivare i messaggi, imposta il prezzo a 0 nel",
    noodzlers_cant_turn_off_messages:
        "I Noodzlers non possono disattivare i messaggi",
    set_messages_price_error:
        "Il prezzo dei messaggi non deve essere superiore al prezzo dell'abbonamento - ",
    set_subscription_price_error:
        "Il prezzo dell'abbonamento deve essere superiore al prezzo dei messaggi - ",
    messages_page: "pagina dei messaggi",
    subscription_page: "pagina di abbonamento",

    request: "Richiesta",
    send_funds: "{username} non può ricevere tips.",
    countdown_text: "Il conto alla rovescia di 10 secondi inizierà per",
    photo: "Foto",
    video: "Video",

    access_camera_title: "Camera e Microfono",
    access_camera_description:
        "Noodzly vuole utilizzare la tua fotocamera e il tuo microfono.",
    access_denied_camera_description:
        "Hai bloccato l'accesso alla fotocamera e al microfono, l'applicazione non funzionerà. Verifica le impostazioni del tuo browser.",
    deny: "Rifiuta",
    allow: "Consenti",
    accept: "Accetta",
    confirm_your_password:
        "Per eliminare il tuo account conferma la tua password",
    password_placeholder: "Inserisci la tua password",
    confirm_and_delete: "Conferma e Elimina",
    subscribe_for_free: "ISCRIVITI GRATUITAMENTE",
    has_no_tips: "Ancora nessuna offerta",
    block_desktop: {
        only_mobile: "Si prega di utilizzare la versione mobile",
        title: "Per un'esperienza migliore, consigliamo di utilizzare la versione mobile. Segui la guida qui sotto:",
        iphone: {
            title: "iPhone",
            list: [
                "Apri Safari sul tuo iPhone e vai su Noodzly.com",
                "Tocca sull'icona \"Condividi\" in basso. Assomiglia a un quadrato con una freccia verso l'alto.",
                'Seleziona l\'icona "Aggiungi a schermata principale".',
                "Goditi!",
            ],
        },
        android: {
            title: "Android",
            list: [
                "Apri Google Chrome e vai su Noodzly.com",
                'Tocca sull\'icona delle "Opzioni". Potrebbe assomigliare a tre punti verticali in alto.',
                'Fai clic su "Aggiungi a schermata principale".',
                "Goditi!",
            ],
        },
    },

    withdrawal_warning: {
        bank_transfer: {
            description: "L'importo minimo per il prelievo è di 100$",
        },
        bitsafe: {
            description: "L'importo minimo per il prelievo è di 50$",
        },
        wire_transfer: {
            description: "L'importo minimo da prelevare è {min_amount}{currency}"
        },
        crypto_wallet: {
            description: "L'importo minimo da prelevare è {min_amount}{currency}"
        },
    },

    withdrawal_form_error: {
        bank_transfer: {
            title: "Avviso",
            description:
                "Aggiungi le informazioni mancanti nella tua pagina della Banca",
        },
        bitsafe: {
            title: "Avviso",
            description:
                "Aggiungi le informazioni mancanti nella tua pagina di Yoursafe",
        },
        wire_transfer: {
            title: "Avviso",
            description: "Aggiungi le informazioni mancanti nella pagina del tuo bonifico bancario internazionale",
        },
        crypto_wallet: {
            title: "Avviso",
            description: "Aggiungi le informazioni mancanti nella tua pagina Crypto",
        },
    },
    no_content_warning: "Carica almeno un contenuto nella tua storia",
    content_expiring: "La scadenza delle tue storie è inferiore alla durata della promozione",
    filter_select: "Seleziona almeno una sezione",
    subscription_cancelled: "Abbonamento annullato con successo",
    renewal_message: "{user_name} cambiato {gender} abbonamento da {old_price} a {new_price}, vorresti rinnovare?",
    subscription: "Sottoscrizione",
    max_price_error: "Il prezzo deve essere compreso tra {min_dm_price} e {max_dm_price}",
    transaction: 'Transazione',
    invalid_tip: 'La mancia deve essere maggiore di 0',
    referral_code_saved: 'Codice di riferimento salvato con successo',
    boost: {
        saved: 'Boost creato con successo',
    },
    post_upload: {
        success: 'Evviva! Successo',
        failed: 'Oops! Riprova',
    },
    ok: 'Va bene',
    tap_back:'Tocca Indietro',
    tap_next:'Tocca Avanti',
    pause:'Pausa',
    business_days: 'Giorni lavorativi',
    fee: 'Tassa',
    payment_method: {
        not_saved: "I dati non vengono salvati, qualcosa è andato storto",
        saved: "Salvato",
    },
    video_guide: {
        swipe_gestures: "Premi e trascina per navigare nel video",
    },
};
