import {isSafari} from "../helpers/functions";
import {VIDEO_WEBM_MIMETYPE, VIDEO_MP4_MIMETYPE} from "../configs/constants";
import EventBus from '../event-bus.js'; 

class StoryCore {
    init(canvas, video, audio = true) {
        this.canvas = canvas
        this.video = video
        this.isFrontCamera = false
        this.isLandscape = 0
        this.idDevice = 0
        this.frontDeviceId = 0
        this.backDeviceId = 0
        this.countDevices = 1
        this.stream = null
        this.mediaRecorder = null
        this.torch = null
        this.devices = null
        this.currentDeviceId = null
        this.widthVideo = null
        this.heightVideo = null
        this.toWidth = null
        this.toHeight = null
        this.posLeft = null
        this.fullWidth = null
        this.ctx = null
        this.audio = audio
        this.zoomLevel = 1.0
        this.maxZoom = 1.0
        this.minZoom = 1.0
        this.audioStream = null,
        this.recordedSessions = [];
        this.currentSessionChunks = [];
        this.sessionAllChunk = [];

        window.addEventListener('orientationchange', function () {
            this.isLandscape = window.orientation;
            [this.toWidth, this.toHeight] = [this.toHeight, this.toWidth];
        });

    }

    async changeCamera() {
        if (!this.stream) {
            return false
        }

        if (this.countDevices > 1) {
            this.idDevice = this.idDevice ? 0 : 1
            this.isFrontCamera = !this.isFrontCamera
        } else {
            this.idDevice = 0
            return false
        }
        this.video.srcObject.getVideoTracks()[0].stop()
        let videoConstraints = {
            deviceId: this.isFrontCamera ? { exact: this.frontDeviceId } : { exact: this.backDeviceId },
            width: { ideal: 1920 },
            height: { ideal: 1080 }
        };
        navigator.mediaDevices.getUserMedia({
            audio: this.audio,
            video: videoConstraints
        }).then(
            async stream => {
                this.stream = stream
                this.video.srcObject = this.stream
                const track = this.video.srcObject.getVideoTracks()[0]

                let constraints = track.getConstraints();

                if (!this.isFrontCamera) {
                    await track.applyConstraints(constraints);
                }
                const settings = track.getSettings()
                this.widthVideo = settings.width
                this.heightVideo = settings.height
            },
            err => console.log(err)
        )

        return true
    }

    setZoom(newZoomLevel) {
        this.zoomLevel = newZoomLevel;
        if (this.stream && this.isZoomSupported) {
            this.stream.getVideoTracks().forEach(track => {
                track.applyConstraints({
                    advanced: [{ zoom: parseFloat(this.zoomLevel.toFixed(2)) }],
                });
            });
        }
    }

    async checkZoomSupport() {
        if (!this.stream) {
            return;
        }
        const capabilities = this.stream.getVideoTracks()[0].getCapabilities();
        this.isZoomSupported = capabilities.zoom !== undefined;
        if (this.isZoomSupported) {
            this.maxZoom = capabilities.zoom.max;
            this.minZoom = capabilities.zoom.min;
            this.setZoom(1.1); // TODO: not working for 1.0, uses 0.5 wide angle lens for some reason
        }
    }

    useTorch(enable) {
        return this.stream.getVideoTracks()[0].applyConstraints({
            //fillLightMode: "torch", /* or "flash" */
            advanced: [{torch: enable}]
        })
    }

    async setDevices() {
        let storyCore = this
        let dev = await navigator.mediaDevices.enumerateDevices()
        storyCore.devices = dev.filter((d) => {
            return d.kind === 'videoinput'
        });
        storyCore.countDevices = storyCore.devices.length;
        this.frontDeviceId = storyCore.devices[0].deviceId
        const backIndex = storyCore.countDevices === 0 || storyCore.countDevices === 1 ? 0 : 1
        this.backDeviceId = storyCore.devices[backIndex].deviceId
    }

    async showVideoFromCamera(funcWH) {
        let storyCore = this
        // storyCore.video.addEventListener('loadeddata', () => {
        //     funcWH([storyCore.widthVideo, storyCore.heightVideo])
        // });
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            if (!this.backDeviceId) {
                await this.setDevices()
            }
            let video = {
                deviceId: {exact: this.backDeviceId},
                width: {
                    min: 1920,
                    max: 3840,
                    ideal: 1920
                },
                height: {
                    min: 1080,
                    max: 2160,
                    ideal: 1080
                }
            }
            if (!this.backDeviceId) {
                video = {
                    facingMode: 'environment'
                }
            }

            await navigator.mediaDevices.getUserMedia({
                audio: this.audio,
                video: video,
            }).then(
                async stream => {
                    if (!this.backDeviceId) {
                        await this.setDevices()
                    }
                    this.stream = stream
                    storyCore.video.srcObject = this.stream
                    const track = storyCore.video.srcObject.getVideoTracks()[0]
                    const constraints = {
                        width: { ideal: 3840 },
                        height: { ideal: 2160 }
                    };
                    await track.applyConstraints(constraints);
                    const settings = track.getSettings()
                    this.widthVideo = settings.width
                    this.heightVideo = settings.height

                    storyCore.video.addEventListener('loadeddata', () => {
                        funcWH([storyCore.widthVideo, storyCore.heightVideo])
                    });
                },
                () => {
                    throw new Error(
                        `Access denied`
                    )
                }
            )

        } else {
            throw new Error(
                `Access denied`
            )
        }
    }
    frameCapture() {
        return this.canvas.toDataURL(this.getMimeType(), 100)
    }

    frameCaptureBlob(blobFunc) {
        const track = this.video.srcObject.getVideoTracks()[0];
        const settings = track.getSettings();

        const fromWidth = settings.width;
        const fromHeight = settings.height;

        const toWidth = fromWidth;
        const toHeight = fromHeight;

        this.canvas.width = toWidth;
        this.canvas.height = toHeight;

        const ctx = this.canvas.getContext('2d');
        if (this.isFrontCamera) {
            ctx.translate(toWidth, 0);
            ctx.scale(-1, 1);
        }

        ctx.drawImage(this.video, 0, 0, fromWidth, fromHeight, 0, 0, toWidth, toHeight);

        return this.canvas.toBlob(blob => blobFunc(blob));
    }

    videoInit() {
        const options = {
            mimeType: this.getMimeType(),
            videoBitsPerSecond: 5 * 1024 * 1024,
        }
        this.recordedChunks = [];
        this.mediaRecorder = new MediaRecorder(this.stream, options);

        let self = this;
        this.mediaRecorder.addEventListener('dataavailable', function (e) {
            if (e.data.size > 0) {
                self.currentSessionChunks.push(e.data);
            }
        });
    }

    stopCamera() {
        if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop())
        }
    }

    startRecordVideo() {
        if (this.mediaRecorder) {
            this.currentSessionChunks = [];
            this.mediaRecorder.start(100);
        }
    }

    stopRecordVideo(callback) {
        if (!this.mediaRecorder) {
            return
        }
        let self = this;
        this.mediaRecorder.addEventListener('stop', function () {
            self.recordedSessions.push([...self.currentSessionChunks]);
            let allChunks = self.recordedSessions.flat();
            callback(new Blob(allChunks, {type: self.getMimeType()}))
        });
        this.mediaRecorder.stop()
    }

    pauseRecordVideo(isSlow) {
        if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
            this.mediaRecorder.pause();
            if (this.currentSessionChunks.length > 0) {
                this.recordedSessions.push([...this.currentSessionChunks]);
                this.sessionAllChunk.push([{video:[...this.currentSessionChunks], slowmo:isSlow ? 'true': 'false' }]);
                this.currentSessionChunks = [];
            }
        }
    }

    resumeRecordVideo() {
        if (this.mediaRecorder && this.mediaRecorder.state === 'paused') {
            this.mediaRecorder.resume();
            this.currentSessionChunks = []; 
        }
    }

    deleteLastSession() {
        if (this.recordedSessions.length > 0) {
            // const lastSessionDuration = this.getSessionDuration(this.recordedSessions.pop());
            // const recordedSessionsLength = this.recordedSessions.length;
            const lastSession = this.sessionAllChunk.pop();
            const lastSessionDuration = this.getSessionDuration(lastSession);
            const recordedSessionsLength = this.sessionAllChunk.length;
            const removeFrom = lastSession[0].slowmo
            return { lastSessionDuration, recordedSessionsLength, removeFrom };
        }
        return { lastSessionDuration: 0, recordedSessionsLength: 0 };
    }

    getSessionDuration(sessionChunks) {
        const intervalMs = 100;
        // return sessionChunks.length * (intervalMs / 1000); // Duration in seconds
        let totalDuration = 0;
        sessionChunks.forEach(chunk => {
            const slowmoFactor = chunk.slowmo === 'true' ? 1 : 1;
            const chunkDuration = chunk.video.length * (intervalMs / 1000);
            totalDuration += chunkDuration * slowmoFactor;
        });
        return totalDuration;
    }

    getMimeType() {
        if (isSafari()) {
            return VIDEO_MP4_MIMETYPE
        }
        return VIDEO_WEBM_MIMETYPE
    }

    async startRecordingAudio() {
        try {
            this.audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            this.mediaRecorder = new MediaRecorder(this.audioStream, {
                mimeType: 'audio/mp4'
            });
            this.mediaRecorder.start();

            this.audioChunks = [];
            let self = this;

            this.mediaRecorder.ondataavailable = (e) => {
                self.audioChunks.push(e.data);
            };

            EventBus.$emit("audioRecordingStarted"); 
        } catch (error) {
            console.error('Error accessing audio device:', error);
        }
    }

    stopRecordingAudio() {
        return new Promise((resolve, reject) => {
            if (this.mediaRecorder) {
                this.mediaRecorder.stop();
                this.mediaRecorder.onstop = () => {
                    const mimeType = this.mediaRecorder.mimeType;
                    const audioBlob = new Blob(this.audioChunks, { type: mimeType });
                    this.audioChunks = [];

                    if (this.audioStream) {
                        this.audioStream.getTracks().forEach(track => track.stop());
                    }

                    resolve(audioBlob);
                };
            } else {
                reject('No recording in progress');
            }
        });
    }

    cancelRecordingAudio() {
        return new Promise((resolve) => {
            if (this.mediaRecorder) {
                if (this.mediaRecorder.state === "inactive") {
                    // Resolve immediately if the mediaRecorder is already inactive
                    resolve('Recording was not started or already stopped');
                } else {
                    this.mediaRecorder.stop();
                    this.mediaRecorder.onstop = () => {
                        this.audioChunks = [];
    
                        if (this.audioStream) {
                            this.audioStream.getTracks().forEach(track => track.stop());
                        }
    
                        resolve('Recording cancelled successfully');
                    };
                }
            } else {
                resolve('No recording in progress');
            }
        });
    }
}

export default new StoryCore()
