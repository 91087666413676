export default {
    success: 'Fatto!',
    password_changed: 'La tua password è stata cambiata',
    something_went_wrong: "Ops.. Qualcosa è andato storto",
    message_seen: 'Visto',
    pin: 'Spillo',
    type_story: 'Storia',
    type_audio: 'Audio',
    slide_to_cancel: 'Scorri per annullare',
    type: {
        audio: "Audio",
        story: "Foto",
        tip: "Mancia",
        creator_tip: '{user} ha richiesto {item}',
    },
}
