export default {
    success: 'Готово!',
    password_changed: 'Ваш пароль был изменен',
    something_went_wrong: "Упс.. Что-то пошло не так",
    message_seen: 'Видимый',
    pin: 'Приколоть',
    type_story: 'История',
    type_audio: 'Аудио',
    slide_to_cancel: 'Проведите пальцем, чтобы отменить',
    type: {
        audio: "Аудио",
        story: "Фото",
        tip: "Кончик",
        creator_tip: '{пользователь} запросил {элемент}',
    },
}
