export default {
    // https://noodzly.com/app/home
    app: {
        home: {
            subscribed: "Suscrito",
            following: "Siguiendo",
            discover: "Descubrir",
        },
    },
    user: {
        setting: {
            main: {
                // https://noodzly.com/user/setting/main
                title: "Configuración",
                delete_account: "Eliminar Cuenta",
                logout: "Cerrar Sesión",
            },
            language: {
                // https://noodzly.com/user/setting/language
                title: "Idioma",
                button: "Listo",
            },
            password: {
                // https://noodzly.com/user/setting/password
                title: "Contraseña",
                old_password: " Vieja Contraseña",
                new_password: "Nueva Contraseña",
                confirm_password: "Confirmar Contraseña",
                update: "Actualizar",
            },
            email: {
                // https://noodzly.com/user/setting/email
                title: "Correo Electrónico",
                email: "Correo Electrónico",
                example: "ejemplo@ejemplo.com",
                update: "Actualizar",
            },
            // https://noodzly.com/user/setting/notification
            notification: {
                title: "Notificaciones",
                app: "App",
                follow: "Seguir",
                new_subscriber: "Nuevo Suscriptor",
                messages: "Mensajes",
                email: "Correo Electrónico",
                fund_sent: "Fondo Enviado",
                tips_received: "Propinas Recibidas",
                save: "Guardar",
            },
            // https://noodzly.com/user/setting/main
            blacklist: {
                title: "Usuarios Bloqueados",
            },
            // https://noodzly.com/user/setting/block-regions
            block_regions: {
                title: "Bloquear Regiones",
            },
            // https://noodzly.com/user/setting/sessions
            sessions: {
                title: "Sesiones",
                something_went_wrong: "¿Algo salió mal?",
                change_your_password: "Cambiar tu Contraseña",
                device: "Dispositivo",
                browser: "Navegador",
                date: "Fecha",
            },
            // https://noodzly.com/user/setting/contact
            contact: {
                title: "Contáctanos",
                placeholder: "¿Qué pasa?",
                send: "Enviar",
            },
            //https://noodzly.com/user/setting/creator
            creator: {
                title: "Noodzler",
                verify: {
                    title: "Verificar Cuenta",
                },
                agreement: {
                    title: "Contrato de Acuerdo",
                    sub_title: "ACUERDO ESTÁNDAR ENTRE NOODZLY Y NOODZLER",
                    field: {
                        name: "Nombre",
                    },
                    btn: {
                        accept: "Aceptar",
                    },
                },
                // https://noodzly.com/user/setting/creator/verify-ondato
                verify_ondato: {
                    title: "Verificar Cuenta con Ondato",
                    verify_account: "Verificar Cuenta",
                    action_link: "comenzar la verificación",
                    add_another_model: "Agregar Otro Noodzler",
                    model_has_verified: "Noodzler {name} ha sido verificado",
                    model_not_verified: "Noodzler {name} no está verificado",
                    status_created: " ",
                    status_processed: "procesado",
                    status_resolved: "resuelto",
                    status_rejected: "rechazado",
                },
                withdraw_options: {
                    title: "Opciones de Retiro",
                    withdraw: {
                        title: "Retiro",
                        bank_transfer: {
                            title: "Transferencia Bancaria",
                            swift_code: "Código SWIFT",
                            placeholder_swift_code: "Insertar Código SWIFT",
                            iban_number: "Código IBAN",
                            placeholder_iban_number: "Insertar Código IBAN",
                            bank_name: "Nombre del Banco",
                            placeholder_bank_name: "Escribir Nombre del Banco",
                            bank_country: "País del Banco",
                            placeholder_bank_country: "País",
                            convert_currency: "Convertiremos USD a EUR el día del pago mediante {ecb_rate_link}",
                            note_1: "Tiempo para recibir el pago: Las transferencias Sepa y Swift se procesan DIARIAMENTE (de lunes a viernes). Los fondos solicitados antes de las 23:59 EST se procesarán al siguiente día hábil. Por favor, permite un tiempo adicional de 5 a 7 días hábiles para que los fondos se depositen en tu cuenta.",
                            note_2: "NOTA: Tu banco (o el banco intermediario) puede cobrarte tarifas además de las tarifas. Las tarifas cobradas por tu banco (o el banco intermediario) no están bajo nuestro control. Consulta con tu banco antes de solicitar un pago si no estás seguro.",
                            beneficiary_name: 'Nombre del beneficiario',
                            placeholder_beneficiary_name: 'Insertar nombre del beneficiario',
                            beneficiary_street: 'Calle del Beneficiario',
                            placeholder_beneficiary_street: 'Insertar calle del beneficiario',
                            beneficiary_city: 'Ciudad beneficiaria',
                            placeholder_beneficiary_city: 'Insertar ciudad beneficiaria',
                            beneficiary_country: 'País Beneficiario',
                            placeholder_beneficiary_country: 'Insertar país beneficiario',
                            beneficiary_state: 'Estado beneficiario',
                            placeholder_beneficiary_state: 'Insertar Estado Beneficiario',
                            beneficiary_zip: 'Código postal del beneficiario',
                            placeholder_beneficiary_zip: 'Insertar código postal del beneficiario',
                        },
                        bitsafe: {
                            title: "Yoursafe",
                            full_name: "Nombre Completo del Beneficiario",
                            iban: "Yoursafe IBAN",
                            country: "País",
                        },
                        wire_transfer: {
                            title: "Transferencia bancaria internacional",
                            swift_code: "Código SWIFT",
                            placeholder_swift_code: "Insertar Código SWIFT",
                            iban_number: "Código IBAN",
                            placeholder_iban_number: "Insertar Código IBAN",
                            bank_name: "Nombre del Banco",
                            placeholder_bank_name: "Escribir Nombre del Banco",
                            bank_country: "País del Banco",
                            placeholder_bank_country: "País",
                            convert_currency: "We will convert USD to EUR on the day of payment by {ecb_rate_link}",
                            note_1: "Time to receive payment: Sepa & Swift payments are now processed DAILY (Monday - Friday). Funds requested prior to 23:59 EST will be processed the following business day. Please allow an additional 5 - 7 business days for the funds to settle in your account.",
                            note_2: "NOTE: Your bank (or intermediary bank) may charge you fees in addition to the fees. Fees charged by your bank (or intermediary bank) are not under our control. Please consult with your bank prior to requesting a payment if you are unsure.",
                            beneficiary_name: 'Nombre del beneficiario',
                            placeholder_beneficiary_name: 'Insertar nombre del beneficiario',
                            beneficiary_street: 'Calle del Beneficiario',
                            placeholder_beneficiary_street: 'Insertar calle del beneficiario',
                            beneficiary_city: 'Ciudad beneficiaria',
                            placeholder_beneficiary_city: 'Insertar ciudad beneficiaria',
                            beneficiary_country: 'País Beneficiario',
                            placeholder_beneficiary_country: 'Insertar país beneficiario',
                            beneficiary_state: 'Estado beneficiario',
                            placeholder_beneficiary_state: 'Insertar Estado Beneficiario',
                            beneficiary_zip: 'Código postal del beneficiario',
                            placeholder_beneficiary_zip: 'Insertar código postal del beneficiario',
                        },
                        crypto_wallet: {
                            title: "Cripto",
                            wallet_address: "Dirección de la billetera criptográfica",
                            placeholder_wallet_address: "Insertar la dirección de la billetera criptográfica",
                            crypto_currency: "Criptomoneda",
                            placeholder_crypto_currency: "Seleccione Criptomoneda",
                            country: "País",
                            placeholder_country: "Seleccione país",
                        },
                    },
                },
                // https://noodzly.com/user/setting/creator/streams
                streams: {
                    title: "Transmisiones Privadas",
                    tabs: {
                        now: "Ahora",
                        later: "Programar",
                    },
                    fields: {
                        price: {
                            title: "Precio",
                        },
                        ticketsSold: {
                            title: "Entradas Vendidas",
                        },
                        viewersLimit: {
                            title: "Límite de Espectadores",
                        },
                        dateTime: {
                            title: "Comienza a las",
                        },
                        isFreeForSubscribers: {
                            title: "Es gratis para suscriptores",
                        },
                        rating: {
                            title: "Puntuación de tus transmisiones",
                        },
                    },
                    actions: {
                        schedule: "Programar",
                        save: "Guardar",
                        goLive: "Ir en Directo",
                    },
                    rules: {
                        title: "Al utilizar programas en vivo, aceptas nuestras ",
                        name: "reglas de flujo",
                    },
                },
                stream: {
                    title: "Transmisión Privada",
                    streamIsNotLive: "La transmisión aún no está en vivo",
                    messages: {
                        system: {
                            joined: "se unió",
                        },
                    },
                    liveReport: {
                        title: "Informe en Vivo",
                        totalTips: "Total de Propinas Recibidas",
                        totalTickets: "Total de Entradas Vendidas",
                        overall: "Total",
                        totalTicketsInfo: "Los ingresos generados por las ventas de entradas para tu transmisión se acreditarán en tu billetera dentro de las 24 horas posteriores a la conclusión de la transmisión.",
                    },
                    liveEnded: {
                        title: "En Vivo Finalizado",
                        rate: {
                            options: {
                                bad: "Malo",
                                ok: "Ok",
                                good: "Bueno",
                                loveIt: "Me Encanta",
                            },
                        },
                    },
                    rules: {
                        title: "Al usar programas en vivo, aceptas nuestras ",
                        name: "reglas de transmisión",
                    },
                    streamingNow: "Transmitiendo ahora",
                    streamStartedOn: "Comenzó hace {time}",
                },
                //https://noodzly.com/user/setting/creator/subscription
                subscriptions: {
                    title: "Suscripciones",
                    subscription_price: "Precio de Suscripción",
                    per_month: "Por mes.",
                    welcome_message: "Mensaje de Bienvenida",
                    welcome_message_rule:
                        "el mensaje de bienvenida se enviará automáticamente a los nuevos suscriptores",
                    save: "Salvar",
                    subscribers: "Suscriptores",
                    saved: "¡Hecho!",
                },
                //https://noodzly.com/user/setting/creator/tips
                tip_menu: {
                    title: "Menú de Propinas",
                    sub_title_1:
                        "Añade artículos o servicios a la venta. Los compradores pueden comprarlos directamente desde tu perfil.",
                    sub_title_2:
                        "Tendrás 3 días para entregar la solicitud del comprador.",
                    enter_name: "Añadir Nombre del Artículo",
                    save: "Salvar",
                    saved: "¡Hecho!",
                    zero_price: "Si deja el precio en 0$, se mostrará como 'A pedido'",
                    on_request: "A solicitud",
                },
                // https://noodzly.com/user/setting/creator/messages
                messages: {
                    title: "Mensajes",
                    direct_messages_price: "Precio de Mensajes Directos",
                    placeholder: "0-49.99",
                    save: "SALVAR",
                    saved: "¡Hecho!",
                    price_suffix: "/Mes",
                    direct_message_info: "El usuario que no quiera suscribirse puede enviarle un mensaje pagando este precio."
                },
                referrals: {
                    title: "Referidos",
                    description:
                        "Por cada persona que se registre en Noodzly utilizando tu código de referencia, recibirás un bono equivalente al 3% de sus ganancias netas durante un período de 12 meses. Este bono se te pagará al mismo tiempo que retiren sus ganancias.",
                    code: "Código",
                    links: "Enlaces",
                    my_referrals: "Mis Referidos",
                    name: "Nombre",
                    status: "Estado",
                    date: "Fecha",
                },
                promotion: {
                    title: "Promoción",
                    description:
                        'La promoción te permite potenciar tu contenido en la sección "Descubrir" y llegar a más personas que pueden convertirse en nuevos suscriptores. Antes de iniciar tu promoción, debes tener al menos un contenido en tu historia.',
                    notags: "Por favor seleccione al menos una etiqueta",
                },
            },
        },
    },
    profile: {
        // https://noodzly.com/profile
        main: {
            creator: "NOODZLER",
            info: "INFORMACIÓN",
            followers: "Seguidores",
            following: "Siguiendo",
            get_app:"Obtener la aplicación",
        },
        // https://noodzly.com/profile/info
        info: {
            title: "Información",
            type_your_bio: "Tu Biografía",
            amazon_url: "URL de Amazon",
            instagram_url: "URL de Instagram",
            twitter_url: "URL de Twitter",
            my_age: "Mi Edad",
            i_am: "Mi sexo",
            male: "Hombre",
            female: "Mujer",
            transgender: "Transgénero",
            categories: "Categorías",
        },
        wallet: {
            // https://noodzly.com/profile/wallet
            withdraw: {
                available_balance: "Saldo Disponible",
                total_net: "Total Neto",
                total_gross: "Total Bruto",
                total_net_info:
                    "El 30% de las tarifas se utiliza para cubrir los costos de procesamiento de pagos, pagos de referencia, alojamiento, soporte y todos los demás servicios de Noodzly.",
                bank_transfer: "Transferencia Bancaria",
                bitsafe: "Yoursafe",
                wire_transfer: "Transferencia bancaria internacional",
                crypto_wallet: "Cripto",
                noodzly_fee: "Tarifa de Noodzly",
                additional_fee: "Tarifa bancaria",
                additinal_fee_info: "La tarifa bancaria se utiliza para cubrir el procesamiento del pago.",
                withdraw_btn: 'Retirar',
            },
        },
        stream: {
            header: "Transmisiones de ",
            title: "Próxima Transmisión Privada",
            purchaseTicket: "Comprar Entrada",
            startWatching: "Empezar a Ver",
            streamingNow: "Transmitiendo ahora",
            streamStartedOn: "Comenzó hace {time}",
        },
        streams: {
            rating: {
                title: "Calificación de Transmisiones",
                stats: {
                    streams: "Vidas",
                    min: "min",
                    averageDuration: "Duración Media",
                },
            },
        },
    },
    // https://noodzly.com/search
    search: {
        placeholder: "Buscar",
    },
    messages: {
        title: "Mensajes",
        search: "Buscar",
        turn_off_direct_messages: "Desactivar mensajes directos",
    },
    channels: {
        title: "Transmisiones",
        yourChannel: "Tu transmisión",
        description: {
            forEveryone:
                "La transmisión es una forma de recibir mensajes de tus Fideos para estar actualizados sobre lo próximo que hagan!",
            forCreators:
                "¡La transmisión te permite enviar mensajes solo a tus suscriptores para mantenerlos informados sobre tu próxima novedad!",
        },
        other_user_broadcast: "Transmisión de {user_name}",
        pinned_message: "Mensaje fijado",
    },
    email_verify: {
        title: "Verificar Correo Electrónico",
        almost_there: "¡Casi Estás!",
        before_link: "Hola {user}, enviamos un correo de confirmación a",
        change: "(Cambiar)",
        after_link: "para empezar, confirma tu correo electrónico.",
        button: "REENVIAR CORREO",
    },
    privacy: {
        title: "Política de Privacidad",
        desc_1: "Al utilizar los servicios de Noodzly.com, aceptas el uso de nuestras cookies. Utilizamos cookies con fines que incluyen análisis, personalización y publicidad.",
        desc_2: "Esta página y algunas otras en este sitio colocan y leen cookies de terceros en tu navegador, que se utilizan con fines no esenciales. A través de estas cookies, nuestros socios recopilan datos personales sobre ti para sus propios fines.",
    },
    home: {
        desktop_description:
            "Noodzly te permite compartir tu contenido amateur de forma totalmente anónima mientras recibes pago, y todo el contenido se eliminará después de 24 horas.",
        qr_code_text: "Escanear el código QR",
        qr_code_sub_text: "Mejor en tu smartphone",
    },
    // https://noodzly.com/user/5/tips
    user_tips: {
        title: "Menú de Propinas de {name}",
        subtitle: "Artículos o servicios en venta por {name}",
        zero_price_text: "¿Quieres solicitar {item_name}?",
        buy_item_text: "¿Quieres comprar {tip_name} por {tip_price}$?",
    },
};
