export default {
    success: '¡Hecho!',
    password_changed: 'Tu contraseña ha sido cambiada',
    something_went_wrong: 'Oops... Algo salió mal',
    message_seen: 'Vista',
    pin: 'Alfiler',
    type_story: 'Historia',
    type_audio: 'Audio',
    slide_to_cancel: 'Deslice para cancelar',
    type: {
        audio: "Audio",
        story: "Foto",
        tip: "Consejo",
        creator_tip: '{usuario} solicitó {artículo}',
    },
}
